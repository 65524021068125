<template>
  <footer class="container-fluid footer_default">
    <b-row class="footer_wrap">
      <div class="foot_left">
        <div class="nav_item" v-for="(item, index) in router" :key="index">
          <p class="title">{{ item.name }}</p>
          <a
            :href="child.link"
            target="_blank"
            class="nav"
            v-for="(child, ind) in item.children"
            :key="ind"
            rel="nofollow"
          >
            {{ child.name }}
          </a>
        </div>
      </div>
      <div class="text-center foot_right foot_right1">
        <img :src="wechat_code1" />
        <p>添加51赞助小助手</p>
        <p>校园营销顾问为您专业解答</p>
      </div>
      <div class="text-center foot_right">
        <img :src="wechat_code" />
        <p>关注51赞助公众号</p>
        <p>第一时间接收赞助信息</p>
      </div>
    </b-row>
    <div class="text-center copyRight">
      Copyright © {{ nowYear }} 51赞助网 &nbsp;<a
        class="link"
        href="https://beian.miit.gov.cn"
        >浙ICP备18008787号-2</a
      >
    </div>
  </footer>
</template>
<script>
export default {
  name: 'footerDefault',
  data() {
    return {
      router: [
        {
          name: '51赞助',
          children: [
            { name: '首页', link: '/' },
            { name: '企业赞助', link: '/sponsor/businessList' },
            { name: '校园活动', link: '/sponsor/studentList' }
          ]
        },
        {
          name: '相关产品',
          children: [
            { name: '校园合伙人', link: '/associate/associate' },
            {
              name: '校果-校园全媒体投放平台',
              link: 'https://www.xiaoguokeji.com/'
            },
            // { name: '校园红人', link: 'http://xkol.xiaoguokeji.com/' },
            // {
            //   name: '品牌联名',
            //   link: 'https://www.xiaoguokeji.com/activity/union'
            // }
          ]
        },
        {
          name: '联系我们',
          children: [
            { name: '客服电话：0571-89925488' },
            { name: '服务时间：周一至周五（9:00-18:30）' },
            { name: '企业地址：浙江省杭州市未来科技城鼎创财富中心B2幢7楼' }
          ]
        }
      ],
      wechat_code: 'http://cdnsponsor.xiaoguokeji.com/51wechatcode.jpg',
      wechat_code1: 'https://img.xiaoguokeji.com/51wechatcode1.png',
      nowYear: ''
    };
  },
  mounted() {
    this.nowYear = new Date().getFullYear();
    (function() {
      var bp = document.createElement('script');
      var curProtocol = window.location.protocol.split(':')[0];
      if (curProtocol === 'https') {
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
      } else {
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
      }
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(bp, s);
    })();
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/vars.scss';
.footer_default {
  min-height: 64px !important;
  background: #5c5c5c;
  color: #fff;
  .footer_wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding: 34px 0;
    display: flex;
    justify-content: space-between;
    .foot_left {
      float: left;
      width: calc(100% - 290px);
      .nav_item {
        float: left;
        margin-right: 125px;
        &:last-child {
          margin-right: 24px;
        }
        .title {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .nav {
          font-size: $baseFontSize;
          color: #ccc;
          margin-bottom: 10px;
        }
      }
    }
    .foot_right {
      float: right;
      width: 120px;
      color: #ccc;
      font-size: 12px;
      &:last-child {
        margin-left: 24px;
      }
      img {
        width: 94px;
        height: 94px;
        margin-bottom: 6px;
      }
      p {
        margin: 0;
      }
    }
      .foot_right1 {
          width: 145px;
      }
    @media screen and (max-width: 750px) {
      .foot_left,
      .foot_right {
        width: 100%;
        &:last-child {
          margin-left: 0;
        }
      }
      .foot_left {
        padding-left: 15px;
        .nav_item {
          margin-right: 50px;
        }
      }
      .foot_right {
          width: 50%;
        img {
          float: none;
        }
      }
    }
  }
  .copyRight {
    width: 100%;
    height: 48px;
    background: #707070;
    color: #ccc;
    font-size: 12px;
    line-height: 48px;
    .link {
      color: #ccc;
      &:hover {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>
